@import '../../../../../../styles/shared';

.job {
  margin-top: 20px;
  font-size: 16px;
  color: var(--hub-product-module-secondary-color);
}

.company {
  font-size: 16px;
  margin-top: 20px;
  font-weight: bold;
}

.wrapper {
  &:hover {
    .job {
      color: #fff;
    }
  }
}
