@import '../../../../../styles/shared';

.slider {
  margin-top: 40px;
  margin-bottom: 40px;

  :global(.slick-list) {
    margin: 0 -10px;
    overflow-x: hidden !important;
  }

  :global(.slick-slide) {
    margin: 0 10px;
  }
}
