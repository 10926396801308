@import '../../../../styles/shared';

.wrapper {
  :global(.slick-list) {
    overflow: visible;
  }

  :global(.slick-arrow) {
    top: 100%;
    width: 48px;
    height: 48px;
    background: rgba(255, 255, 255, 0.25);
    border: 0;
    border-radius: 50%;
    z-index: 1;

    &:hover {
      background: var(--color-scheme-accent-main);
    }

    @include mq($from: wide) {
      top: 50%;
      transform: translate(0, -50%);
    }

    &::before {
      position: relative;
      top: -1px;
      font-family: 'Material Icons', sans-serif;
      font-size: 22px;
      color: #fff;
    }
  }

  :global(.slick-prev) {
    left: 20px;

    @include mq($from: wide) {
      left: 5px;
    }

    @include mq($from: 1440px) {
      left: -5px;
    }

    &::before {
      content: 'west';
    }
  }

  :global(.slick-next) {
    right: 20px;

    @include mq($from: wide) {
      right: 5px;
    }

    @include mq($from: 1440px) {
      right: -5px;
    }

    &::before {
      content: 'east';
    }
  }
}
